import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useMount } from '@dy/commons/hooks'
import Susy from '@dy/commons/susy'
import { Navbar } from './navbar'
import { Footer } from './footer'
import { useModal, ModalNewsletter, ModalContact, ModalLanguage, modalContactId, modalNewsletterId } from '@/modals'
import { cookie } from '@/utils'
import { NavbarBackgroundContext } from '@/components'

const STAGING_PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local'

export const Layout = ({ children = null, data }) => {
  const isMount = useMount()
  const { locale, pathname, asPath, query } = useRouter()
  // const { open } = useModal(modalLanguageId)
  const { open:openModalContact } = useModal(modalContactId)
  const { open:openModalNewsletter } = useModal(modalNewsletterId)
  const [hasNavbarBackground, setHasNavbarBackground] = useState(false)

  useEffect(() => {
    if (pathname === '/products/[slug]' || pathname === '/usa/products/[slug]') {
      setHasNavbarBackground(true)
    } else {
      setHasNavbarBackground(false)
    }
  }, [pathname])

  useEffect(() => {
    setTimeout(() => {
      if(!cookie.get('NEXT_LOCALE')) {
        cookie.set('NEXT_LOCALE', locale)
      }
      // if(!cookie.get('NEXT_LOCALE')) open()
    }, 1000)
  }, [])

  useEffect(() => {
    if(!query) return
    if (query?.modal === 'contact') openModalContact()
    else if (query?.modal === 'newsletter') openModalNewsletter()
  }, [query])

  return (
    <>
      <NavbarBackgroundContext.Provider value={{ hasNavbarBackground, setHasNavbarBackground }}>
        {(data && isMount) && <Navbar menu_data={data.navbar} />}
          {children}
        {(data && isMount) && <Footer data={data.footer} />}
      </NavbarBackgroundContext.Provider>
      {(isMount && !STAGING_PRO) && <Susy hide={false} columnColor={'rgba(255,0,0,.015)'} columnBorder={'1px solid rgba(255,0,0,.1)'} />}
      <ModalNewsletter />
      <ModalContact />
      <ModalLanguage asPath={asPath}/>
    </>
  )
}
