import { memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Body, Aside } from './MenuMobileLayout'
import { useMount } from '@/hooks'
import { Multilevel } from './Multilevel'

interface IModalMenuMobile {
  data?: any[]
  isActive: boolean,
  onClickCross: any,
  ref?: any,
  locale?: any,
  setArrowLevel?: any,
  currentLevel?: any,
  setState?: any,
}

export const modalMenuMobileId = 'modal-menu-mobile'
export const ModalMenuMobile = memo<IModalMenuMobile>(({ data, isActive, onClickCross, setArrowLevel, currentLevel, setState }) => {
  const isMounted = useMount()
  const { asPath } = useRouter()
  useEffect(() => {
    function close() {
      onClickCross()
    }

    const debounceTimer = setTimeout(close, 500)
    return () => clearTimeout(debounceTimer)

  },[asPath])

  return (isMounted ?
    <Aside $isActive={isActive}>
    <Body isActive={isActive}>
      <main>
        <Multilevel close={onClickCross} items={data} setArrowLevel={setArrowLevel} currentLevel={currentLevel} setState={setState} />
      </main>
    </Body></Aside> : <></>
  )
})
